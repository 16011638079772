export default {
    //获取url参数
    getUrlKey: function (name) {
        return (
            decodeURIComponent(
                (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
                    location.href
                ) || [, ""])[1].replace(/\+/g, "%20")
            ) || null
        );
    },
    //判断是否是json字符串
    isJsonString(str) {
        try {
            if (str === null) {
                return false;
            }
            if (typeof JSON.parse(str) == "object") {
                return true;
            }
        } catch (e) {
        }
        return false;
    },
    /**
     * 处理登录时用户的登录权限信息, 及权限管理设置权限时的信息 统一方法.
     * @param {Object} obj  用户登录时返回的user_info 或者 权限管理设置权限时每个用户对应的权限数据
     * @returns {Object} 返回处理好的权限信息
     * 返回格式例如:{
     *     userid:'11545465',
     *     index:{  //index为首页模块
     *         power:{
     *             look:0
     *         }
     *     }
     * }
     * 具体对应权限参考\src\components\authority\set_power2.vue
     */
    init_power(obj) {
        let user_info = JSON.parse(JSON.stringify(obj));
        //各模块默认值
        //首页
        let first_page_power = {
                power: {
                    look: 0,//可查看
                },
                area: ''
            },
            //任务管理
            task_power = {
                power: {
                    look: 0,//查看所有任务
                    register: 0,//任务督办
                    appraise: 0,//任务评价
                }
            },
            //市项目管理
            shi_project_project_power = {
                power: {
                    look: 0,
                    month_form: 0,//新增月报
                    edit: 0,//修改项目基础信息
                    upload: 0,//上传材料(项目管理?没有得到明确答复,暂时对应项目管理的权限)
                    change: 0,//变更项目(因与修改项目权限冲突,改为在项目详情中变更项目操作)
                    stop: 0,//终止项目
                    delete: 0,//删除项目
                },
                area: '',//1,2,3...对应地区的值, -1为所有地区的项目,''为没有选择所属地区,没有选择所属地区时,项目列表获取登录人为联络小组负责人的项目
            },
            //区项目管理
            qu_project_power = {
                power: {
                    look: 0,//可查看
                    edit: 0,//可编辑
                },
                area: '',
            },
            //自筹项目管理
            zichou_project_power = {
                power: {
                    look: 0,
                    edit: 0,
                },
                area: '',
            },
            //市级项目资金
            fund_project_power = {
                power: {
                    look: 0,
                    edit: 0,
                },
                area: '',
            },
            //项目审计
            shenji_project_power = {
                power: {
                    look: 0,
                    edit: 0,
                },
                area: '',
            },
            //委托服务管理
            weituo_power = {
                power: {
                    look: 0,
                    edit: 0,
                },
                area: '',
            },
            //项目评估
            pinggu_project_power = {
                power: {
                    look: 0,
                    edit: 0,
                },
                area: '',
            },
            //储备项目
            chubei_project_power = {
                power: {
                    look: 0,
                    edit: 0,
                    up: 0,
                },
                area: '',
            },
            //统计分析
            statistics_power = {
                power: {
                    look: 0,
                    edit: 0,
                },
                area: '',
            },
            //权限管理
            power_power = {
                power: {
                    look: 0,
                    edit: 0,
                },
            },
            //三交项目报备
            three_cross_power = {
                power: {
                    look: 0,
                    edit: 0,
                },
                area: '',
            },
            // 果洛是否可见
            sz_guoluo = {
                power: {
                    look:0
                }
            },
            // 区县概况权限
            xygk_power = {
                power:{
                    look:0
                },
                area: ""
            };
            

        //以前的字段处理
        if (user_info.part && Number(user_info.part)) {
            user_info.part = Number(user_info.part);
        } else {
            user_info.part = 0;
        }
        if (user_info.area && Number(user_info.area)) {
            user_info.area = Number(user_info.area);
        } else {
            user_info.area = '';
        }
        if (user_info.all_project && Number(user_info.all_project)) {
            user_info.all_project = Number(user_info.all_project);
        } else {
            user_info.all_project = 0;
        }
        if (!user_info.fuze_users || Number(user_info.fuze_users) === 0) {
            user_info.fuze_users = '';
        }

        if (user_info.first_page_power && this.isJsonString(user_info.first_page_power)) {
            user_info.first_page_power = JSON.parse(user_info.first_page_power);
            if (user_info.first_page_power.area === undefined || user_info.first_page_power.area === null) {
                user_info.first_page_power.area = '';
            }
        } else {
            user_info.first_page_power = first_page_power;
        }

        if (user_info.task_power && this.isJsonString(user_info.task_power)) {
            user_info.task_power = JSON.parse(user_info.task_power);
        } else {
            user_info.task_power = task_power;
        }

        if (user_info.shi_project_project_power && this.isJsonString(user_info.shi_project_project_power)) {
            user_info.shi_project_project_power = JSON.parse(user_info.shi_project_project_power);
        } else {
            user_info.shi_project_project_power = shi_project_project_power;
        }

        if (user_info.qu_project_power && this.isJsonString(user_info.qu_project_power)) {
            user_info.qu_project_power = JSON.parse(user_info.qu_project_power);
        } else {
            user_info.qu_project_power = qu_project_power;
        }

        if (user_info.zichou_project_power && this.isJsonString(user_info.zichou_project_power)) {
            user_info.zichou_project_power = JSON.parse(user_info.zichou_project_power);
        } else {
            user_info.zichou_project_power = zichou_project_power;
        }

        if (user_info.fund_project_power && this.isJsonString(user_info.fund_project_power)) {
            user_info.fund_project_power = JSON.parse(user_info.fund_project_power);
        } else {
            user_info.fund_project_power = fund_project_power;
        }

        if (user_info.shenji_project_power && this.isJsonString(user_info.shenji_project_power)) {
            user_info.shenji_project_power = JSON.parse(user_info.shenji_project_power);
        } else {
            user_info.shenji_project_power = shenji_project_power;
        }

        if (user_info.weituo_power && this.isJsonString(user_info.weituo_power)) {
            user_info.weituo_power = JSON.parse(user_info.weituo_power);
        } else {
            user_info.weituo_power = weituo_power;
        }

        if (user_info.pinggu_project_power && this.isJsonString(user_info.pinggu_project_power)) {
            user_info.pinggu_project_power = JSON.parse(user_info.pinggu_project_power);
        } else {
            user_info.pinggu_project_power = pinggu_project_power;
        }

        if (user_info.chubei_project_power && this.isJsonString(user_info.chubei_project_power)) {
            user_info.chubei_project_power = JSON.parse(user_info.chubei_project_power);
            //储备项目增加了个项目升级权限
            if (user_info.chubei_project_power.power && !user_info.chubei_project_power.power.up) {
                user_info.chubei_project_power.power.up = 0;
            }
        } else {
            user_info.chubei_project_power = chubei_project_power;
        }

        if (user_info.statistics_power && this.isJsonString(user_info.statistics_power)) {
            user_info.statistics_power = JSON.parse(user_info.statistics_power);
        } else {
            user_info.statistics_power = statistics_power;
        }

        if (user_info.power_power && this.isJsonString(user_info.power_power)) {
            user_info.power_power = JSON.parse(user_info.power_power);
        } else {
            user_info.power_power = power_power;
        }

        if (user_info.three_cross_power && this.isJsonString(user_info.three_cross_power)) {
            user_info.three_cross_power = JSON.parse(user_info.three_cross_power);
        } else {
            user_info.three_cross_power = three_cross_power;
        }
        if (user_info.sz_guoluo && this.isJsonString(user_info.sz_guoluo)) {
            user_info.sz_guoluo = JSON.parse(user_info.sz_guoluo);
        } else {
            user_info.sz_guoluo = sz_guoluo;
        }
        if (user_info.xygk_power && this.isJsonString(user_info.xygk_power)) {
            user_info.xygk_power = {
                area:"",
                ...JSON.parse(user_info.xygk_power)
            };
        } else {
            user_info.xygk_power = xygk_power;
        }
        return user_info;
    },
    /**
     *
     * @param date 标准时间日期格式
     * @param fmt 格式化格式
     * @returns {string}
     */
    dateFormat(date, fmt = 'YYYY-MM-DD HH:mm:ss') {
        date = new Date(date).getTime();
        date = new Date(date);
        var o = {
            'M+': date.getMonth() + 1,
            'D+': date.getDate(),
            'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
            'H+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds(),
            'q+': Math.floor((date.getMonth() + 3) / 3),
            'S': date.getMilliseconds()
        };
        var week = {
            '0': '\u65e5',
            '1': '\u4e00',
            '2': '\u4e8c',
            '3': '\u4e09',
            '4': '\u56db',
            '5': '\u4e94',
            '6': '\u516d'
        };
        if (/(Y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        if (/(E+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? '\u661f\u671f' : '\u5468') : '') + week[date.getDay() + '']);
        }
        for (var k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
            }
        }
        return fmt;
    },

    validateMobileNumber(mobileNumber) {
        // 中国大陆手机号码正则表达式
        const regex = /^1[3-9]\d{9}$/;
        
        // 检查手机号码是否匹配正则表达式
        return regex.test(mobileNumber);
    }
};