import http from '../getData'
import { getUser } from '../common'
export default {
    offplanformAdd(params) {
        if (getUser(params)) {
            return http.post('offplanformAdd', getUser(params))
        }
    },
    offplanformLists(params) {
        if (getUser(params)) {
            return http.post('offplanformLists', getUser(params))
        }
    },
    offplanformDelete(params) {
        if (getUser(params)) {
            return http.post('offplanformDelete', getUser(params))
        }
    },
    offplanformOut(params) {
        if (getUser(params)) {
            return http.get('offplanformOut', getUser(params))
        }
    },
    poolAdd(params) {
        if (getUser(params)) {
            return http.post('poolAdd', getUser(params))
        }
    },
    poolLists(params) {
        if (getUser(params)) {
            return http.post('poolLists', getUser(params))
        }
    },
    poolDelete(params) {
        if (getUser(params)) {
            return http.post('poolDelete', getUser(params))
        }
    },
    poolOut(params) {
        if (getUser(params)) {
            return http.get('poolOut', getUser(params))
        }
    },
    allList(params) {
        if (getUser(params)) {
            return http.post('allList', getUser(params))
        }
    },
    poolAllList(params) {
        if (getUser(params)) {
            return http.post('poolAllList', getUser(params))
        }
    }
}