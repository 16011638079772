import http from "../getData";
import { getUser } from "../common";

export default {
	// 部门列表
	GetChildDepartmentInfo(params) {
		if (getUser(params)) {
			return http.post("GetChildDepartmentInfo", getUser(params));
		}
	},
	// 人员列表
	GetUsersByDeptId(params) {
		if (getUser(params)) {
			return http.post("GetUsersByDeptId", getUser(params));
		}
	},
	// 获取部门/人员详情
	GetInfoByIds(params) {
		if (getUser(params)) {
			return http.post("GetInfoByIds", getUser(params));
		}
	},
};
