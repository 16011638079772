import http from '../getData'
import { getUser } from '../common'
export default {
    taskList(params) {
        if (getUser(params)) {
            return http.post('taskList', getUser(params))
        }
    },
    taskDetail(params) {
        if (getUser(params)) {
            return http.post('taskDetail', getUser(params))
        }
    },
    delTask(params) {
        if (getUser(params)) {
            return http.post('delTask', getUser(params))
        }
    },
    addTask(params) {
        if (getUser(params)) {
            return http.post('addTask', getUser(params))
        }
    },
    childTaskList(params) {
        if (getUser(params)) {
            return http.post('childTaskList', getUser(params))
        }
    },
    taskReport(params) {
        if (getUser(params)) {
            return http.post('taskReport', getUser(params))
        }
    },
    reportLists(params) {
        if (getUser(params)) {
            return http.post('reportLists', getUser(params))
        }
    },
    taskRrportAppraise(params) {
        if (getUser(params)) {
            return http.post('taskRrportAppraise', getUser(params))
        }
    },
    taskAppraise(params) {
        if (getUser(params)) {
            return http.post('taskAppraise', getUser(params))
        }
    },
    statisticsLists(params) {
        if (getUser(params)) {
            return http.post('statisticsLists', getUser(params))
        }
    },
    noReportTime(params) {
        if (getUser(params)) {
            return http.post('noReportTime', getUser(params))
        }
    },
    taskRegister(params) {
        if (getUser(params)) {
            return http.post('taskRegister', getUser(params))
        }
    },
    reportDelete(params) {
        if (getUser(params)) {
            return http.post('reportDelete', getUser(params))
        }
    },
    reportDeatil(params) {
        if (getUser(params)) {
            return http.post('reportDeatil', getUser(params))
        }
    },
    getReportTime(params) {
        if (getUser(params)) {
            return http.post('getReportTime', getUser(params))
        }
    },
    yuqiNoReport(params) {
        if (getUser(params)) {
            return http.post('yuqiNoReport', getUser(params))
        }
    },
}