export default {
    offplanformAdd:'/index.php/cqh/Offplanform/add',//保存修改项目进度月报表
    offplanformLists:'/index.php/cqh/Offplanform/lists',//项目进度月度报表列表
    offplanformDelete:'/index.php/cqh/Offplanform/delete',//删除项目进度月度报表
    offplanformOut:'/index.php/cqh/Offplanform/leadingOut',//导出项目进度月度报表

    poolAdd:'/index.php/cqh/Pool/add',//保存修改项目进度汇总表
    poolLists:'/index.php/cqh/Pool/lists',//项目进度汇总表列表
    poolDelete:'/index.php/cqh/Pool/delete',//删除项目进度汇总表
    poolOut:'/index.php/cqh/Pool/leadingOut',//导出项目进度汇总表
    poolAllList:"/index.php/cqh/Pool/allLists", // 项目进度汇总
    allList:"/index.php/cqh/Offplanform/allList", // 项目进度月度列表
}