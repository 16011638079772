import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from './vuex/index'

Vue.config.productionTip = false;

function onInput(el, ele, binding, vnode) {
    function handle() {
        let val = ele.value
        // modifiers为修饰符对象，传入了float，则其float属性为true
        if (binding.modifiers.float) {
            console.log(val,'val')
            // 清除"数字"和"."以外的字符
            val = val.replace(/[^\d.]/g, '')
            // 只保留第一个, 清除多余的
            val = val.replace(/\.{2,}/g, '.')
            // 第一个字符如果是.号，则补充前缀0
            val = val.replace(/^\./g, '0.')
        } else {
            val = ele.value.replace(/[^\d]/g, '')
        }
        ele.value = val
    }

    return handle
}

const numberInput = {
    bind(el, binding, vnode) {
        const ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
        ele.addEventListener('input', onInput(el, ele, binding, vnode), false)
    },
}
Vue.directive('number-input', numberInput)

import {
    Message,
    MessageBox,
    Button,
    Select,
    Option,
    Input,
    InputNumber,
    Dialog,
    Table,
    TableColumn,
    Pagination,
    Form,
    FormItem,
    DatePicker,
    Popover,
    Loading,
    Progress,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Tooltip,
    Radio,
    RadioGroup
} from 'element-ui';

Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$loading = Loading.service;
// Vue.prototype.$confirm = MessageBox.confirm;
Vue.use(Button);
Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Dialog);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(DatePicker);
Vue.use(Popover);
Vue.use(Loading.directive);
Vue.use(Progress);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tooltip);
Vue.use(Radio);
Vue.use(RadioGroup);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
