import Vue from 'vue'
import Vuex from 'vuex'
import user from './module/user';
import task from './module/task'

Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        user: user,
        task:task,
    }
})
export default store
