//钉钉有关接口
export default {
	login: "/index.php/cqh/ding/login", //钉钉登录
	syncUsers: "/index.php/cqh/ding/syncUsers", //同步用户
	getCorpSign: "/index.php/cqh/ding/getCorpSign", //获取js签名所需参数
	grantCustomSpace: "/index.php/cqh/ding/grantCustomSpace", //上传下载附件
	simulatedLogin: "/index.php/cqh/Ding/simulatedLogin", //模拟登录
	getCode: "/index.php/cqh/Remind/getCode", // 获取验证码
	loginBrowser: "/index.php/cqh/ding/webLogin", // 网页端登录
	getUserInfo: "/index.php/cqh/Employeeext/GetUserInfo", // 获取用户信息
	LoginOut: "/index.php/cqh/ding/LoginOut", // 退出登录

	upload: "/index.php/cqh/File/upload", // 上传附件
	getAllFile: "/index.php/cqh/File/getAllFile", // 所有附件
	delFile:'/index.php/cqh/File/delFile', //标记删除附件
};
