import http from '../getData'
import { getUser } from '../common'
export default {
    fundLists(params) {
        if (getUser(params)) {
            return http.post('fundLists', getUser(params))
        }
    },
    addFund(params) {
        if (getUser(params)) {
            return http.post('addFund', getUser(params))
        }
    },
    deleteFund(params) {
        if (getUser(params)) {
            return http.post('deleteFund', getUser(params))
        }
    },
    fundDetail(params) {
        if (getUser(params)) {
            return http.post('fundDetail', getUser(params))
        }
    },
    confirmFund(params) {
        if (getUser(params)) {
            return http.post('confirmFund', getUser(params))
        }
    },
    listNotFund(params) {
        if (getUser(params)) {
            return http.post('listNotFund', getUser(params))
        }
    },
    projectMoneyOut(params) {
        if (getUser(params)) {
            return http.get('projectMoneyOut', getUser(params))
        }
    },
}