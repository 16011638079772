/**
 * 判断打开程序的容器
 * dd : 钉钉
 * wx : 企业微信
 * browser : 浏览器
 */
const openContainer = navigator.userAgent.toLowerCase();
const container = {
	dd: openContainer.includes("dingtalk"),
	wx: openContainer.includes("wxwork"),
	browser:
		!openContainer.includes("dingtalk") && !openContainer.includes("wxwork"),
};
export default container;
