/**
 * 钉钉鉴权获取验证
 */
import methods from '@/api/methods/index'
import { Storage } from '@/utils/storage'
import logins from '@/utils/login'
import store from '@/vuex/index'
import {Message} from "element-ui";
export default {
    ready_login(callbackFn,mode) {
        var _url = window.location.href;
        var _url = _url.split("#")[0];

        let self = this;
        var params = {
            'url': _url
        };
        methods.login.getCorpSign(params).then(function(data) {
            if (data.data.state == 1) {
                console.log('执行login_before获取认证信息成功')
                store.commit('user/SETCONFIG',data.data.data.config);
                self.justready(data.data.data.config, function() {
                    (callbackFn && typeof(callbackFn) === "function") && callbackFn(data)
                },mode);
            } else {
                Message.error('获取钉钉认证信息失败,请关闭重试');
            }
        });
    },
    justready: function(_config, callback,mode) {
        let self = this;
        console.log('_config:'+JSON.stringify(_config));
        dd.config({
            agentId: _config.agentId,
            corpId: _config.corpId,
            timeStamp: _config.timeStamp,
            nonceStr: _config.nonceStr,
            signature: _config.signature,
            jsApiList: [
                'runtime.permission.requestAuthCode',
                'biz.util.uploadAttachment',
                'biz.cspace.preview',
                'biz.contact.choose',
                'biz.contact.complexPicker',
                'biz.util.openLink',
                'biz.contact.departmentsPicker'
            ]
        });
        dd.userid = 0;

        dd.ready(function() {
            //判断是否登录过了
            if(mode){
                callback()
            }else{
                dd.runtime.permission.requestAuthCode({
                    corpId: _config.corpId, //企业id
                    onSuccess: function(info) {
                        Storage.setItem('code', info.code);
                        //钉钉登录返回token
                        console.log('获取code成功')
                        logins.loginByDingding(function() {
                            callback()
                        });
                    },
                    onFail: function(err) {
                        Message.error('登录失败,请关闭重试');
                    }
                });
            }
        });

        dd.error(function(err) {
            let err_message = ['ticket', '签名校验失败', '权限校验失败'];
            Message.error('应用错误请关闭重试!')
            if ((JSON.stringify(err).indexOf(err_message[0]) >= 0) || (JSON.stringify(err).indexOf(err_message[1]) >= 0) || (JSON.stringify(err).indexOf(err_message[2]) >= 0)) {
                self.ready_login(function () {})
            }
        });

    }
}
