import http from '../getData'
import { getUser } from '../common'
export default {
    addReserveProject(params) {
        if (getUser(params)) {
            return http.post('addReserveProject', getUser(params))
        }
    },
    reserveProjectList(params) {
        if (getUser(params)) {
            return http.post('reserveProjectList', getUser(params))
        }
    },
    deleteReserveProject(params) {
        if (getUser(params)) {
            return http.post('deleteReserveProject', getUser(params))
        }
    },
    reserveProjectDetail(params) {
        if (getUser(params)) {
            return http.post('reserveProjectDetail', getUser(params))
        }
    },
    reserveprojectUpgrade(params) {
        if (getUser(params)) {
            return http.post('reserveprojectUpgrade', getUser(params))
        }
    },
    toProject(params) {
        if (getUser(params)) {
            return http.post('toProject', getUser(params))
        }
    },
}