import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/vuex/index";
import ddready from "@/utils/ddready";
import utils from "@/utils/public.js";
import container from "@/utils/container.js";
import util_login from "@/utils/login.js";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: "/pc/index/all",
	},
	{
		path: "/pc",
		name: "home",
		component: () => import("@/views/Home.vue"),
		redirect: "/pc/index/all",
		children: [
			{
				path: "index",
				name: "index",
				component: () => import("@/views/index/index.vue"),
				children: [
					{
						path: "all",
						name: "index_all",
						component: () => import("@/views/index/all/all.vue"),
					},
					{
						path: "unplanned",
						name: "index_unplanned",
						component: () => import("@/views/index/unplanned/unplanned.vue"),
					},
				],
			},
			{
				path: "project",
				name: "project",
				component: () => import("@/views/project/project.vue"),
			},
			{
				path: "three_cross",
				name: "three_cross",
				component: () => import("@/views/three_cross/three_cross.vue"),
				redirect: "/pc/three_cross/static",
				children: [
					{
						path: "report",
						name: "three_cross_report",
						component: () => import("@/views/three_cross/report/report.vue"),
					},
					{
						path: "static",
						name: "three_cross_static",
						component: () => import("@/views/three_cross/static/static.vue"),
					},
				],
			},
			{
				path: "money",
				name: "money",
				component: () => import("@/views/money/money.vue"),
			},
			{
				path: "unplanned",
				name: "unplanned",
				component: () => import("@/views/unplanned/unplanned.vue"),
			},
			{
				path: "reserve",
				name: "reserve",
				component: () => import("@/views/reserve/reserve.vue"),
			},
			{
				path: "statistics",
				name: "statistics",
				component: () => import("@/views/statistics/statistics.vue"),
				redirect: "/pc/statistics/all",
				children: [
					{
						path: "all",
						name: "statistics_all",
						component: () => import("@/views/statistics/all/all.vue"),
					},
					{
						path: "unplan",
						name: "statistics_unplan",
						component: () => import("@/views/statistics/unplan/unplan.vue"),
					},
				],
			},
			{
				path: "authority",
				name: "authority",
				component: () => import("@/views/authority/authority.vue"),
			},
			{
				path: "report",
				name: "report",
				component: () => import("@/views/report/report.vue"),
				redirect: "/pc/report/all",
				children: [
					{
						path: "month",
						name: "report_month",
						component: () => import("@/views/report/month/month.vue"),
					},
					{
						path: "all",
						name: "report_all",
						component: () => import("@/views/report/all/all.vue"),
					},
				],
			},
			{
				path: "audit",
				name: "audit",
				component: () => import("@/views/audit/audit.vue"),
			},
			{
				path: "check",
				name: "check",
				component: () => import("@/views/check/check.vue"),
			},
			{
				path: "assess",
				name: "assess",
				component: () => import("@/views/assess/assess.vue"),
			},
			{
				path: "task",
				name: "task",
				component: () => import("@/views/task/index.vue"),
				redirect: "/pc/task/all",
				children: [
					{
						path: "all",
						name: "task_all",
						component: () => import("@/views/task/all/all.vue"),
					},
					{
						path: "static",
						name: "task_static",
						component: () => import("@/views/task/static/static.vue"),
					},
				],
			},
			// 区县概括
			{
				path: "quxiangaikuo",
				name: "quxiangaikuo",
				component: () => import("@/views/quxiangaikuo/index.vue"),
			},
			{
				path: "system_file_list",
				name: "system_file_list",
				component: () => import("@/views/system_all_file/index.vue"),
			},
		],
	},
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/login/browser.vue"),
	},
];

const router = new VueRouter({
	base: process.env.BASE_URL,
	routes,
});

// 白名单
const white_list = ["/login"];

router.beforeEach(async (to, from, next) => {
	if (to.path === "/") {
		next();
	}

	if (utils.getUrlKey("system") || white_list.includes(to.path)) {
		next();
	} else {
		if (store.state.user.firstGo == 0) {
			// 登录
			if (container.dd) {
				ddready.ready_login(function() {
					store.state.user.firstGo++;
					next();
					console.log("route登录");
				}, false);
			} else if (container.browser) {
				const token = localStorage.getItem("token");
				if (token) {
					await util_login.loginByToken(token);
					next();
				} else {
					next({ path: "/login" });
				}
			}
		} else {
			next();
		}
	}
});

export default router;
