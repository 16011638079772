import store from "@/vuex/index";
import api from "@/api/methods/index";
import { Storage } from "@/utils/storage";
import myPublic from "@/utils/public.js";
import router from "@/router/index.js";

export default {
	current_user_id: "",
	//钉钉登录返回token
	loginByDingding: function(callback) {
		var self = this;
		var code = Storage.getItem("code");
		var params = {
			code: code,
		};
		api.login.login(params).then(function(data) {
			console.log("byding:" + JSON.stringify(data));
			if (data.data.state == 1) {
				store.commit("user/SETTOKEN", data.data.data.token);
				let corp = data.data.data.corp || {};
				let user_info = myPublic.init_power(data.data.data.info);
				console.log(user_info, "登录返回的user_info");
				function initWsMonitor() {
					if (!window.wsMonitor) {
						setTimeout(() => {
							initWsMonitor();
						});
					} else {
						window.__wsm = window.wsMonitor({
							host: "https://rwgjoapi.linanquan.com",
							group: "test",
							topic: "上海援青",
							userId: user_info.userid,
							groupId: corp.corp_id,
							router: router,
							platform: "pc", //pc,phone
							isVue3: false,
						});
					}
				}

				initWsMonitor();
				store.commit("user/SETCURRENTUSER", user_info);
				store.commit("user/SETCORP", data.data.data.corp);
				// store.commit('user/SETfIRSTGO', 1);
				console.log("登录成功");
				api.project.getConfig({ token: data.data.data.token }).then((res) => {
					if (res.data.state == 1) {
						store.commit("user/SETPROJECTEDIT", res.data.data);
					}
				});
				callback();
			}
		});
	},
	//本地存储数据（加过期时间）
	setStorage: function(key, value, exp) {
		var curTime = new Date().getTime();
		Storage.setItem(
			key,
			JSON.stringify({ data: value, time: curTime, exp: exp })
		);
	},
	//获取存储数据
	getStorage: function(key) {
		var data = Storage.getItem(key);
		if (!data) {
			return "";
		} else {
			var dataObj = JSON.parse(data);
			//console.log(dataObj);
			if (new Date().getTime() - dataObj.time > dataObj.exp) {
				console.log("信息已过期");
				return "";
			} else {
				var justData = dataObj.data;
				return justData;
			}
		}
	},

	// 根据token登录
	loginByToken: async (token) => {
		if (!token) return;
		store.commit("user/SETTOKEN", token);
		const msg = await api.login.getUserInfo({ token });
		const user_info = myPublic.init_power(msg.data.rows);
		store.commit("user/SETCURRENTUSER", user_info);
		api.project.getConfig({ token }).then((res) => {
			if (res.data.state == 1) {
				store.commit("user/SETPROJECTEDIT", res.data.data);
			}
		});
		store.state.user.firstGo++;
	},
};
