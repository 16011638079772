import http from '../getData'
import { getUser } from '../common'
export default {
    getProjectNumber(params) {
        if (getUser(params)) {
            return http.post('getProjectNumber', getUser(params))
        }
    },
    getOffProjectNumber(params) {
        if (getUser(params)) {
            return http.post('getOffProjectNumber', getUser(params))
        }
    },
    GetOpenSign(params) {
        if (getUser(params)) {
            return http.post('GetOpenSign', getUser(params))
        }
    },
}