export default {
    taskList:'/index.php/cqh/Task/lists',//任务列表
    taskDetail:'/index.php/cqh/Task/detail',//任务详情
    delTask:'/index.php/cqh/Task/delete',//删除任务
    addTask:'/index.php/cqh/Task/add',//新增,编辑任务
    childTaskList:'/index.php/cqh/Task/childLists',//子任务列表
    taskReport:'/index.php/cqh/Task/task_report',//任务上报
    reportLists:'/index.php/cqh/Task/reportLists',//任务上报列表
    taskRrportAppraise:'/index.php/cqh/Task/taskRrportAppraise',//对上报的任务评价
    taskAppraise:'/index.php/cqh/Task/taskAppraise',//任务评价
    statisticsLists:'/index.php/cqh/Task/statisticsLists',//任务统计列表
    noReportTime:'/index.php/cqh/Task/noReportTime',//未上报任务列表
    taskRegister:'/index.php/cqh/task/taskSupervise',//任务督办
    reportDelete:'/index.php/cqh/Task/reportDelete',//删除已上报任务
    reportDeatil:'/index.php/cqh/Task/reportDeatil',//任务上报详情
    getReportTime:'/index.php/cqh/Task/getReportTime',//获取上报周期
    yuqiNoReport:'/index.php/cqh/Task/yuqiNoReport',//任务逾期未上报人员
}