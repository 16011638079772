export default {
    namespaced: true,
    state: {
        show:false,//任务弹出框显示
        history:[{type:'add',task_id:0,father_task_id:0,task_rank:0}],//跳转历史
    },
    mutations: {
        SETHISTORT(state, data) {
            let history;
            if (data.key === 'push') {
                state.history.push(data.value);
                history = Array.from(new Set(state.history)).filter((item) => JSON.stringify(item) !== JSON.stringify({}))
            } else if (data.key === 'cover') {
                state.history = data.value;
                history = Array.from(new Set(state.history)).filter((item) => JSON.stringify(item) !== JSON.stringify({}))
            } else if (data.key === 'splice') {
                state.history.forEach((item, index) => {
                    // if (data.value.task_id === item.task_id&&data.value.type === item.type) {
                    if(JSON.stringify(data.value)===JSON.stringify(item)){
                        state.history.splice(index, 1);
                    }
                })
                history = state.history;
            }
            state.history = history;
        },
        SETSHOW(state,data){
            state.show = data;
        }
    },
    actions: {

    },
    modules: {

    }
}