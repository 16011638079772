/**
 * Created by Administrator on 2017/12/14.
 */
import api from "./apilist/index";
import axios from "axios";
import qs from "qs";
import router from "@/router/index.js";
import { Message } from "element-ui";

axios.interceptors.request.use(
	(config) => {
		// loading
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	(response) => {
		// console.log('响应拦截器',response)
		return response;
	},
	(error) => {
		return Promise.resolve(error.response);
	}
);

function checkStatus(response) {
	// 如果http状态码正常，则直接返回数据
	if (
		response &&
		(response.status === 200 ||
			response.status === 304 ||
			response.status === 400)
	) {
		return response;
		// 如果不需要除了data之外的数据，可以直接 return response.data
	}
	// 异常状态下，把错误信息返回去
	Message.error("网络异常或服务端出错，请重新登录");
	return {
		state: -404,
		msg: "网络异常或服务端出错",
	};
}

function checkCode(res) {
	// 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
	if (res.data.state == 1) {
		return res;
	} else if (res.data.state == 1000) {
		Message.error("登录失效，请重新登录");
		router.replace({ path: "/login" });
		return res;
	} else if (res.data.state === -404) {
		Message.error("服务器出错，请重新登录");
		return res;
	} else {
		Message.error(res.data.msg);
		return res;
	}
}

export default {
	post(
		host,
		data,
		config = {
			"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
		}
	) {
		// console.log('data',qs.stringify(data));
		return axios({
			method: "post",
			url: api[host],
			data: qs.stringify(data),
			headers: config,
		})
			.then((response) => {
				return checkStatus(response);
			})
			.then((res) => {
				return checkCode(res);
			})
			.catch(function(err) {
				console.log(err);
			});
	},
	postJson(host, data, config) {
		// console.log('data',qs.stringify(data));
		return axios({
			method: "post",
			url: api[host],
			data: data,
			headers: config,
		})
			.then((response) => {
				return checkStatus(response);
			})
			.then((res) => {
				return checkCode(res);
			})
			.catch(function(err) {
				console.log(err);
			});
	},
	get(pathName, params) {
		let _url = window.location.href;
		let __url = _url.split("#")[0];
		let url = api[pathName];
		return axios({
			method: "get",
			baseURL: __url,
			url,
			params, // get 请求时带的参数
		})
			.then((response) => {
				return checkStatus(response);
			})
			.then((res) => {
				return checkCode(res);
			})
			.catch(function(err) {
				console.log(err);
			});
	},
};
