import http from "../getData";
import { getUser } from "../common";
export default {
	xygkConfig(params) {
		if (getUser(params)) {
			return http.postJson("xygkConfig", getUser(params));
		}
	},
	xygkGet(params) {
		if (getUser(params)) {
			return http.postJson("xygkGet", getUser(params));
		}
	},
	xygkSave(params) {
		if (getUser(params)) {
			return http.postJson("xygkSave", getUser(params));
		}
	},
};
