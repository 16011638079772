/**
 * Created by SYSTEM on 2018/7/2.
 * 用于本地保存用的，手机端用localStorage，电脑端用sessionStorage
 */

var Storage;
var userAgentInfo = navigator.userAgent;
var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
Storage= sessionStorage
for (var v = 0; v < Agents.length; v++) {
  if (userAgentInfo.indexOf(Agents[v]) > 0) {
    Storage= localStorage
    break;
  }
}

export { Storage }
