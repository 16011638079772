import store from '@/vuex/index'
/*
* obj 要传的参数
*/
export function getUser(obj){
    var user = obj ? obj : {};
    user.token = store.state.user.token;
    return user;
}

/*
export function projectParam(obj){
    var user = obj ? obj : {};
    user.token = store.state.user.token;
    if(store.state.user.current_user.all_project===1){
        // console.log('查看全部项目')
    }else if(store.state.user.current_user.fuze_users) {
        user.fuze_users = store.state.user.current_user.fuze_users;
    }else if(store.state.user.current_user.area){
        user.area=store.state.user.current_user.area;
    }else{
        user.fuze_users = store.state.user.current_user.userid;
    }
    return user;
}*/
