import http from '../getData';
import {getUser} from '../common';

export default {
    threeCrossSave(params) {
        if (getUser(params)) {
            return http.post('threeCrossSave', getUser(params));
        }
    },
    threeCrossList(params) {
        if (getUser(params)) {
            return http.post('threeCrossList', getUser(params));
        }
    },
    threeCrossDel(params) {
        if (getUser(params)) {
            return http.post('threeCrossDel', getUser(params));
        }
    },
    reportState(params) {
        if (getUser(params)) {
            return http.post('reportState', getUser(params));
        }
    },
    threeCrossStatic(params) {
        if (getUser(params)) {
            return http.post('threeCrossStatic', getUser(params));
        }
    },
    threeCrossStaticReport(params) {
        if (getUser(params)) {
            return http.get('threeCrossStaticReport', getUser(params));
        }
    },
    threeCrossReport(params) {
        if (getUser(params)) {
            return http.get('threeCrossReport', getUser(params));
        }
    },
};