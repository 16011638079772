import http from '../getData'
import { getUser } from '../common'
export default {
    projectList(params) {
        if (getUser(params)) {
            return http.post('projectList', getUser(params))
        }
    },
    addProject(params) {
        if (getUser(params)) {
            return http.post('addProject', getUser(params))
        }
    },
    addForm(params) {
        if (getUser(params)) {
            return http.post('addForm', getUser(params))
        }
    },
    addApp(params) {
        if (getUser(params)) {
            return http.post('addApp', getUser(params))
        }
    },
    addAuditCheckAssess(params) {
        if (getUser(params)) {
            return http.post('addAuditCheckAssess', getUser(params))
        }
    },
    projectDetail(params) {
        if (getUser(params)) {
            return http.post('projectDetail', getUser(params))
        }
    },
    formLists(params) {
        if (getUser(params)) {
            return http.post('formLists', getUser(params))
        }
    },
    stopProject(params) {
        if (getUser(params)) {
            return http.post('stopProject', getUser(params))
        }
    },
    deleteProject(params) {
        if (getUser(params)) {
            return http.post('deleteProject', getUser(params))
        }
    },
    yearFinish(params) {
        if (getUser(params)) {
            return http.post('yearFinish', getUser(params))
        }
    },
    detailForm(params) {
        if (getUser(params)) {
            return http.post('detailForm', getUser(params))
        }
    },
    appLists(params) {
        if (getUser(params)) {
            return http.post('appLists', getUser(params))
        }
    },
    appDetail(params) {
        if (getUser(params)) {
            return http.post('appDetail', getUser(params))
        }
    },
    projectLeadingOut(params) {
        if (getUser(params)) {
            return http.get('projectLeadingOut', getUser(params))
        }
    },
    monthLeadingOut(params) {
        if (getUser(params)) {
            return http.get('monthLeadingOut', getUser(params))
        }
    },
    projectNumOut(params) {
        if (getUser(params)) {
            return http.get('projectNumOut', getUser(params))
        }
    },
    projectExportByQuality(params) {
        if (getUser(params)) {
            return http.get('projectExportByQuality', getUser(params))
        }
    },
    managementEdit(params) {
        if (getUser(params)) {
            return http.post('managementEdit', getUser(params))
        }
    },
    getCheckItems(params) {
        if (getUser(params)) {
            return http.post('getCheckItems', getUser(params))
        }
    },
    checkItemDetail(params) {
        if (getUser(params)) {
            return http.post('checkItemDetail', getUser(params))
        }
    },
    checkItemDel(params) {
        if (getUser(params)) {
            return http.post('checkItemDel', getUser(params))
        }
    },
    appDelete(params) {
        if (getUser(params)) {
            return http.post('appDelete', getUser(params))
        }
    },
    getConfig(params) {
        if (getUser(params)) {
            return http.post('getConfig', params)
        }
    },
    editConfig(params) {
        if (getUser(params)) {
            return http.post('editConfig', getUser(params))
        }
    },
    projectListout(params) {
        if (getUser(params)) {
            return http.get('projectListout', getUser(params))
        }
    },
    reportList(params) {
        if (getUser(params)) {
            return http.post('reportList', getUser(params))
        }
    },
    delReport(params) {
        if (getUser(params)) {
            return http.post('delReport', getUser(params))
        }
    },
    addReport(params) {
        if (getUser(params)) {
            return http.post('addReport', getUser(params))
        }
    },
}