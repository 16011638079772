export default {
    namespaced: true,
    state: {
        //当前用户信息
        current_user: {

        },
        firstGo: 0, //判断用户是否是第一次进入页面
        corp:{
            app_id:'',
            corp_id: '',
            agent_id: '',
            space_id: ''
        },
        config:{},//js鉴权返回的config
        token:'',
        project_edit_data:{},//项目修改权限project_edit为1：可以修改 2：不能修改
        search_year: new Date().getFullYear().toString(),
        edit_project_info: {},//修改的项目信息主要是用于查看项目名称
    },
    mutations: {
        SETCURRENTUSER(state, data) {
            state.current_user = data
        },
        SETCORP(state, data) {
            state.corp = data
        },
        SETTOKEN(state, data) {
            state.token = data
        },
        SETCONFIG(state, data) {
            state.config = data
        },
        SETfIRSTGO(state, data) {
            state.firstGo = data
        },
        SETPROJECTEDIT(state, data) {
            state.project_edit_data = data
        },
        SETYEAR(state, data) {
            state.search_year = data
        },
        SETEDITPROJECTINFO(state, data) {
            state.edit_project_info = data;
        },
    },
    actions: {

    },
    modules: {

    }
}