import http from "../getData";
import { getUser } from "../common";
export default {
	login(params) {
		if (getUser(params)) {
			return http.post("login", params);
		}
	},
	syncUsers(params) {
		if (getUser(params)) {
			return http.post("syncUsers", getUser(params));
		}
	},
	getCorpSign(params) {
		if (getUser(params)) {
			return http.post("getCorpSign", params);
		}
	},
	grantCustomSpace(params) {
		if (getUser(params)) {
			return http.post("grantCustomSpace", getUser(params));
		}
	},
	simulatedLogin(params) {
		if (getUser(params)) {
			return http.post("simulatedLogin", getUser(params));
		}
	},
	getCode(params) {
		return http.postJson("getCode", params);
	},
	loginBrowser(params) {
		return http.postJson("loginBrowser", params);
	},
	LoginOut(params) {
		if (getUser(params)) {
			return http.post("LoginOut", getUser(params));
		}
	},
	getUserInfo(params) {
		return http.postJson("getUserInfo", params);
	},
	upload(params) {
		if (getUser(params)) {
			const data = getUser(params);
			const formData = new FormData();
			for (const key in data) {
				formData.append(key, data[key]);
			}

			return http.postJson("upload", formData, {
				"Content-Type": "multipart/form-data",
			});
		}
	},
	getAllFile(params) {
		if (getUser(params)) {
			return http.post("getAllFile", getUser(params));
		}
	},
	delFile(params) {
		if (getUser(params)) {
			return http.post("delFile", getUser(params));
		}
	},
};
