import http from '../getData'
import { getUser } from '../common'
export default {
    staffLists(params) {
        if (getUser(params)) {
            return http.post('staffLists', getUser(params))
        }
    },
    startOff(params) {
        if (getUser(params)) {
            return http.post('startOff', getUser(params))
        }
    },
    editPartArea(params) {
        if (getUser(params)) {
            return http.post('editPartArea', getUser(params))
        }
    },
    getRemindConfig(params) {
        if (getUser(params)) {
            return http.post('getRemindConfig', getUser(params))
        }
    },
    saveRemindConfig(params) {
        if (getUser(params)) {
            return http.post('saveRemindConfig', getUser(params))
        }
    },
}