export default {
    projectList:'/index.php/cqh/Project/projectList',//项目列表
    addProject:'/index.php/cqh/Project/addProject',//项目初始化
    addForm:'/index.php/cqh/Form/addForm',//新建修改月报表
    addApp:'/index.php/cqh/App/addApp',//保存修改拨付款
    addAuditCheckAssess:'/index.php/cqh/Project/addAuditCheckAssess',//添加修改项目审计，检查，评估情况
    projectDetail:'/index.php/cqh/Project/projectDetail',//项目详情
    formLists:'/index.php/cqh/Form/formLists',//月报表列表
    stopProject:'/index.php/cqh/project/stopProject',//终止项目
    deleteProject:'/index.php/cqh/Project/deleteProject',//删除项目
    yearFinish:'/index.php/cqh/Project/yearFinish',//项目年度完成
    detailForm:'/index.php/cqh/Form/detailForm',//项目月报表详情
    appLists:'/index.php/cqh/App/appLists',//拨付款列表
    appDetail:'/index.php/cqh/App/appDetail',//拨付表详情
    projectLeadingOut:'/index.php/cqh/Project/projectLeadingOut',//项目进度汇总导出
    monthLeadingOut:'/index.php/cqh/Project/monthLeadingOut',//项目月度报表导出
    projectNumOut:'/index.php/cqh/Project/projectNumOut',//按类别导出项目
    managementEdit:'/index.php/cqh/Project/managementEdit',//新增修改项目管理，立项前期费用
    getCheckItems:'/index.php/cqh/Projectmanage/lists',//日常检查情况记录列表
    checkItemDetail:'/index.php/cqh/Projectmanage/detail',//检查情况详情
    checkItemDel:'/index.php/cqh/Projectmanage/delete',//删除项目检查日志
    appDelete:'/index.php/cqh/App/appDelete',//拨付款删除
    getConfig:'/index.php/cqh/Config/getConfig',//获取项目修改权限
    editConfig:'/index.php/cqh/Config/editConfig',//项目修改权限修改
    projectListout:'/index.php/cqh/Project/projectListout',//统计分析导出
    reportList:'/index.php/cqh/Projectreport/lists',//项目技术报告列表
    delReport:'/index.php/cqh/Projectreport/delete',//删除技术报告
    addReport:'/index.php/cqh/Projectreport/add',//新增技术报告
    projectExportByQuality:'/index.php/cqh/Export/projectExportByQuality',//上海对口援青项目实施进度统计表导出
}