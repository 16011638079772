import http from '../getData'
import { getUser } from '../common'
export default {
    addOffPlan(params) {
        if (getUser(params)) {
            return http.post('addOffPlan', getUser(params))
        }
    },
    offPlanLists(params) {
        if (getUser(params)) {
            return http.post('offPlanLists', getUser(params))
        }
    },
    offPlanDetail(params) {
        if (getUser(params)) {
            return http.post('offPlanDetail', getUser(params))
        }
    },
    deleteOffPlan(params) {
        if (getUser(params)) {
            return http.post('deleteOffPlan', getUser(params))
        }
    },
    listsExport(params) {
        if (getUser(params)) {
            return http.get('listsExport', getUser(params))
        }
    },
    Offplanstatistics(params) {
        if (getUser(params)) {
            return http.post('Offplanstatistics', getUser(params))
        }
    },
}