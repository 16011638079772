import home from "./home";
import project from "./project";
import money from "./money";
import unplanned from "./unplanned";
import reserve from "./reserve";
import statistics from "./statistics";
import authority from "./authority";
import login from "./login";
import report from "./report";
import task from "./task";
import threeCross from "./threeCross";
import quxian from "./quxian";
import user from "./user";
export default {
	home,
	project,
	money,
	unplanned,
	reserve,
	statistics,
	authority,
	login,
	report,
	task,
	threeCross,
	quxian,
	user,
};
